import { Buffer } from 'buffer'
import { format, startOfWeek, subDays } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import _ from 'lodash'
import LogRocket from 'logrocket'
import { parse } from 'uuid'
import storage from 'constants/storage'
import type { User } from 'constants/types'
import * as cookie from 'libs/cookie'
import enterprise from 'libs/enterprise'
import formatDate from 'libs/format-date'
import subscriptions from 'libs/subscriptions'

export const FACEBOOK_SRC_ID = 'facebook'
export const MASS_ADVANTAGE_SRC_ID = 'mass-advantage'

const TYPE_ADMIN = 'admin'
const TYPE_TRAINER = 'trainer'
export const TYPE_CORE = 'core'
const TYPE_ENTERPRISE = 'enterprise'
export const TYPE_ENTERPRISE_TERMINATED = 'enterprise_terminated'

function isUser(user: User) {
  if (!user) return false
  return user && user.userType
}

function isAdminUser(user: User) {
  return isUser(user) && user.userType === TYPE_ADMIN
}

function isTrainerUser(user: User) {
  return isUser(user) && user.userType === TYPE_TRAINER
}

export function isEnterpriseUser(user: User) {
  return isUser(user) && user.userType === TYPE_ENTERPRISE
}

export function isEnterpriseTerminatedUser(user: User) {
  return isUser(user) && user.userType === TYPE_ENTERPRISE_TERMINATED
}

export function isPainUser(user: User) {
  return isUser(user) && user.userExerciseProgram?.exerciseProgram?.tags?.includes('pain')
}

export function isEnterpriseDemoClinicalUser(user: User) {
  return (
    isEnterpriseUser(user) &&
    user.enterpriseMember?.enterprise?.internalId === enterprise.DEMO_CLINICAL_KEY
  )
}

export function isEnterpriseCuttlefishUser(user: User) {
  return (
    isEnterpriseUser(user) &&
    _.startsWith(user.enterpriseMember?.enterprise?.internalId, enterprise.CUTTLEFISH_KEY)
  )
}

export function isEnterpriseGatorUser(user: User) {
  return (
    isEnterpriseUser(user) && user.enterpriseMember?.enterprise?.internalId === enterprise.GATOR_KEY
  )
}

export function isEnterpriseGrouperUser(user: User) {
  return (
    isEnterpriseUser(user) &&
    user.enterpriseMember?.enterprise?.internalId === enterprise.GROUPER_KEY
  )
}

export function isEnterpriseHerringUser(user: User) {
  return (
    isEnterpriseUser(user) &&
    user.enterpriseMember?.enterprise?.internalId === enterprise.HERRING_KEY
  )
}

export function isEnterpriseHumpbackUser(user: User) {
  return (
    isEnterpriseUser(user) &&
    user.enterpriseMember?.enterprise?.internalId === enterprise.HUMPBACK_KEY
  )
}

export function isEnterpriseReefUser(user: User) {
  return (
    isEnterpriseUser(user) && user.enterpriseMember?.enterprise?.internalId === enterprise.REEF_KEY
  )
}

export function isEnterpriseOctopusUser(user: User) {
  return (
    isEnterpriseUser(user) &&
    user.enterpriseMember?.enterprise?.internalId === enterprise.OCTOPUS_KEY
  )
}

export function isEnterpriseOtterUser(user: User) {
  return (
    isEnterpriseUser(user) && user.enterpriseMember?.enterprise?.internalId === enterprise.OTTER_KEY
  )
}

export function canRefer(user: User) {
  return isUser(user) && !isEnterpriseOctopusUser(user)
}

export function isEnterpriseOrcaUser(user: User) {
  return (
    isEnterpriseUser(user) && user.enterpriseMember?.enterprise?.internalId === enterprise.ORCA_KEY
  )
}

export function isEnterpriseReefOrOrcaUser(user: User) {
  return isEnterpriseOrcaUser(user) || isEnterpriseReefUser(user)
}

export function isEnterpriseTrialUser(user: User) {
  return isEnterpriseUser(user) && user.enterpriseMember?.enterprise?.internalId === 'trial'
}

export function isTrialUser(user: User) {
  return isUser(user) && user.subscription?.status === 'trialing'
}

export function isEnterpriseFitnessUser(user: User) {
  return (
    isEnterpriseCuttlefishUser(user) ||
    isEnterpriseGatorUser(user) ||
    isEnterpriseHerringUser(user) ||
    isEnterpriseOrcaUser(user) ||
    isEnterpriseReefUser(user) ||
    isEnterpriseTrialUser(user)
  )
}

// janky but keep terminated clinical members as clinical during their 30-day free trial window
// to continue same UX, but then they should be treated as fitness after, even though their user type
// will still be terminated after those 30 days, they can either go to Basic or Premium & won't be trialing
export function isEnterpriseClinicalUser(user: User) {
  return (
    isEnterpriseDemoClinicalUser(user) ||
    isEnterpriseGrouperUser(user) ||
    // isEnterpriseHumpbackUser(user) ||
    isEnterpriseOctopusUser(user) ||
    isEnterpriseOtterUser(user) ||
    (isEnterpriseTerminatedUser(user) &&
      isTrialUser(user) &&
      enterprise.isClinical(user?.enterpriseMember?.enterprise?.internalId))
  )
}

export function isFreeUser(user: User) {
  return (
    isUser(user) &&
    !isEnterpriseUser(user) &&
    (subscriptions.isFreePlan(user?.subscription?.plan) || user?.subscription?.plan == null)
  )
}

export function isFromPaidSource(user: User) {
  return isUser(user) && user.srcId === FACEBOOK_SRC_ID
}

export function isFreeOrTrialUser(user: User) {
  return isUser(user) && (subscriptions.isFreePlan(user?.subscription?.plan) || isTrialUser(user))
}

function canChangePlan(user: User) {
  return isFreeOrTrialUser(user)
}

function getPlan(user: User) {
  if (!user || !user.subscription) {
    LogRocket.captureMessage('user.subscription in getPlan')
  }
  return user?.subscription?.plan
}

function getPlanName(user: User) {
  if (!user) return ''
  return `Bold ${subscriptions.getPlanName(getPlan(user))}${isTrialUser(user) ? ' Trial' : ''}`
}

function getPlanLevel(user: User) {
  return isFreeUser(user) ? getPlanName(user) : 'Bold Premium'
}

function getEnterprisePartnerLogo(user: User) {
  const internalId = user?.enterpriseMember?.enterprise?.internalId
  const srcId = user?.srcId
  if (!internalId && !srcId) return ''
  return enterprise.getLogo(internalId, user)
}

function getEnterprisePartnerName(user: User) {
  return user?.enterpriseMember?.enterprise?.name ?? ''
}

function getEnterpriseUserInternalId(user: User) {
  return user.enterpriseMember?.enterprise?.internalId ?? ''
}

function canChangeProgram(user: User) {
  return !isFreeUser(user) && !isEnterpriseOctopusUser(user)
}

export function hasDashboard(user: User) {
  return !isEnterpriseClinicalUser(user) && !isFreeUser(user)
}

export function hasExplore(user: User) {
  return !isEnterpriseClinicalUser(user) && !isFreeUser(user)
}

function hasHome(value: string) {
  return cookie.getCookie(storage.HOME_KEY) === value
}

export function hasHomeSpecial() {
  return hasHome(storage.HOME_SPECIAL)
}

export function hasPassword(user: User) {
  if (user?.loginType === 'facebook' || user?.loginType === 'google') {
    return false
  }
  return !isEnterpriseOctopusUser(user)
}

function hasLiveLessons(user: User) {
  return !isFreeUser(user)
}

function isBirthdayToday(user: User) {
  const today = new Date()
  const birthday = new Date(user.dateOfBirth.replaceAll('-', '/'))
  // https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off

  return birthday.getDate() === today.getDate() && birthday.getMonth() === today.getMonth()
}

export function getPromoCode() {
  return cookie.getCookie(storage.PROMO_CODE_KEY)
}

export function hasChallenges(user: User) {
  return !isEnterpriseClinicalUser(user)
}

export function hasPromoCode(value: unknown) {
  return value ? getPromoCode() === value : getPromoCode()
}

export function hasSubscription(user: User) {
  return !isFreeUser(user) && !isEnterpriseUser(user)
}

export function isActiveUser(user: User) {
  const status = user.activityStatus
  return status === 'active'
}

export function getUserTestGroup(user, testSplits = 2) {
  const parsedUserId = parse(user.id)
  // const deterministicNumberSpecificToUser =
  //   parsedUserId && parsedUserId.length > 0 && parsedUserId.slice(-1)
  const userIdBuffer = Buffer.from(parsedUserId)
  const result = userIdBuffer.readUInt32BE(0)
  return result % testSplits
}

export function getUniqueDaysActiveThisWeek(lessons) {
  // standardize everything to PT timezone
  const now = new Date()
  const startOfWeekInTargetTZ = utcToZonedTime(
    startOfWeek(now, { weekStartsOn: 1 }),
    formatDate.TIMEZONE
  )
  const sundayThisWeek = subDays(startOfWeekInTargetTZ, 1) // week starts on Monday in date-fns but we start on Sunday

  const lessonsByZonedDate = lessons?.map((item) => {
    const itemDate = new Date(item.createdAt)
    const zonedItemDate = utcToZonedTime(itemDate, formatDate.TIMEZONE)
    return {
      ...item,
      zonedItemDate,
    }
  })

  const lessonsThisWeek = lessonsByZonedDate?.filter((item) => item.zonedItemDate >= sundayThisWeek)
  const uniqueDaysActive = _.uniqBy(lessonsThisWeek, (item) =>
    format(item.zonedItemDate, 'yyyy-MM-dd')
  )

  return uniqueDaysActive
}

export function isOnboardingPaymentRequired(user: User) {
  return [MASS_ADVANTAGE_SRC_ID].includes(user?.srcId)
}

export default {
  FACEBOOK_SRC_ID,
  TYPE_ADMIN,
  TYPE_CORE,
  TYPE_ENTERPRISE,

  canChangePlan,
  canChangeProgram,
  canRefer,
  getEnterprisePartnerLogo,
  getEnterprisePartnerName,
  getEnterpriseUserInternalId,
  getPlanLevel,
  getPlanName,
  getPromoCode,
  getUniqueDaysActiveThisWeek,
  getUserTestGroup,
  hasChallenges,
  hasDashboard,
  hasExplore,
  hasHome,
  hasHomeSpecial,
  hasLiveLessons,
  hasPassword,
  hasPromoCode,
  hasSubscription,
  isActiveUser,
  isAdminUser,
  isBirthdayToday,
  isEnterpriseClinicalUser,
  isEnterpriseDemoClinicalUser,
  isEnterpriseFitnessUser,
  isEnterpriseGrouperUser,
  isEnterpriseHumpbackUser,
  isEnterpriseOctopusUser,
  isEnterpriseOtterUser,
  isEnterpriseReefOrOrcaUser,
  isEnterpriseTerminatedUser,
  isEnterpriseTrialUser,
  isEnterpriseUser,
  isFreeOrTrialUser,
  isFreeUser,
  isFromPaidSource,
  isOnboardingPaymentRequired,
  isPainUser,
  isTrainerUser,
  isTrialUser,
}
