import * as events from 'constants/events'
import environment from 'libs/environment'

const featuresPremium = [
  'Personalized programs and support from Bold’s expert coaches',
  'Assess your balance, strength and mobility as you progress',
  'Access on-demand library of strength, cardio, yoga, pilates classes',
  'Attend live classes and premium experiences with experts and coaches',
]

type Plan = {
  amount: number
  features: string[]
  id: string
  interval: string
  key: string
  name: string
}

const TRIAL_DAYS = 14

// order matters here
const PLANS = {
  basic: {
    key: 'basic',
    name: 'Basic',
    amount: 0,
    features: [
      'Try a limited selection of five Bold classes',
      'Assess your balance, strength and mobility',
      'Access a limited set of live classes',
    ],
    id: !environment.isProduction ? 'plan_GCRycsHSY6cuBv' : 'price_1KTvouH7hkV8tQE3ZQAcpOvx',
    interval: 'month',
  },
  month: {
    key: 'month',
    name: 'Monthly',
    name2: 'Monthly',
    amount: 3499,
    features: featuresPremium,
    billingFrequency: 'monthly',
    id: environment.isProduction
      ? 'price_1OK2LhH7hkV8tQE3H4NR7h8C'
      : 'price_1OJipIH7hkV8tQE3p046Tzh5',
    interval: 'month',
    freeTrialDays: TRIAL_DAYS,
  },
  biAnnual: {
    key: 'biAnnual',
    name: '6 Months',
    name2: '6 Months',
    amount: 15_699,
    features: featuresPremium,
    billingFrequency: 'bi-annually',
    id: environment.isProduction
      ? 'price_1OK2LIH7hkV8tQE3VJVIAcgF'
      : 'price_1OJimVH7hkV8tQE3ocRqaxp2',
    interval: 'biAnnual',
    freeTrialDays: TRIAL_DAYS,
  },
  year: {
    key: 'year',
    name: '12 Months',
    name2: 'Annual',
    amount: 24_899,
    features: featuresPremium,
    billingFrequency: 'annually',
    id: environment.isProduction
      ? 'price_1OK2KpH7hkV8tQE3Piza0sws'
      : 'price_1OJiRuH7hkV8tQE34zk9EtxV',
    interval: 'year',
    freeTrialDays: TRIAL_DAYS,
  },
}

interface CouponObject {
  name: string
  amount_off?: number
  percent_off?: number
}

interface PlanObject {
  interval: string
  interval_count: number
  amount: number
  id: string
  eligible_coupons: Array<CouponObject>
}

const isPlan = (plan: PlanObject) => plan && plan.interval

export const isAnnualPlan = (plan: PlanObject) =>
  isPlan(plan) && plan.interval === 'year' && plan.amount !== 0

export const isBiAnnualPlan = (plan: PlanObject) =>
  isPlan(plan) && plan.interval === 'month' && plan.interval_count === 6 && plan.amount !== 0

export const isFreePlan = (plan: PlanObject) =>
  isPlan(plan) && plan.interval === 'month' && plan.amount === 0

export const isMonthlyPlan = (plan: PlanObject) =>
  isPlan(plan) && plan.interval === 'month' && plan.interval_count === 1 && plan.amount !== 0

const hasFreeTrial = (plan: PlanObject) => isAnnualPlan(plan)

const isPlanFreeTrialEligible = (plan: PlanObject, currentPlan: PlanObject) => {
  if (isPlan(currentPlan)) {
    // we had a previous plan
    // we never want to show a free trial here because we're always in change plan
    return false
  }

  if (isPlan(plan) && hasFreeTrial(plan)) {
    // we do not have a previous plan, so we're not changing subscriptions
    // we check to see if what we're selecting has a free trial
    return true
  }

  // we do not have a previous plan and our current plan does not have a free trial, we return false
  return false
}

const getPlanById = (_plans: Array<PlanObject>, id: string) => _plans.find((item) => item.id === id)

const getPlanLevel = (plan: PlanObject) => {
  let planKey = PLANS.basic.key

  if (isAnnualPlan(plan)) planKey = PLANS.year.key
  if (isMonthlyPlan(plan)) planKey = PLANS.month.key
  if (isBiAnnualPlan(plan)) planKey = PLANS.biAnnual.key

  return planKey
}

const getPlanFeatures = (plan: PlanObject) => PLANS[getPlanLevel(plan)].features

const getPlanFreeTrialDays = (plan: PlanObject) => (isAnnualPlan(plan) ? TRIAL_DAYS : 0)

const getPlanLevel2 = (plan: PlanObject) => (isFreePlan(plan) ? 'Bold Basic' : 'Bold Premium')

const getPlanName = (plan: PlanObject) => PLANS[getPlanLevel(plan)].name

const findPromoCode = (promoCode: string, planData: Array<PlanObject>) => {
  promoCode = promoCode.toLowerCase().trim()

  for (const plan of planData) {
    if (plan.eligible_coupons !== undefined) {
      const foundCoupon = plan.eligible_coupons.find(
        (coupon: CouponObject) => promoCode === coupon.name.toLowerCase()
      )
      if (foundCoupon) {
        return foundCoupon
      }
    }
  }

  return null
}

const calculateAmountInCentsWithCoupon = (
  amount: number,
  coupon?: CouponObject,
  plan?: Plan
): number => {
  if (coupon && coupon.amount_off) {
    const denominator = isMonthlyPlan(plan) ? 12 : 1
    return amount - coupon.amount_off / denominator / 100
  }

  if (coupon && coupon.percent_off) {
    return amount - (amount * coupon.percent_off) / 100
  }

  return amount
}

export const getSubscriptionEventName = (plan: PlanObject): string => {
  if (isMonthlyPlan(plan)) {
    return events.SUBSCRIPTION_PREMIUM_MONTHLY
  }
  if (isBiAnnualPlan(plan)) {
    return events.SUBSCRIPTION_PREMIUM_BI_ANNUAL
  }
  if (isAnnualPlan(plan)) {
    return events.SUBSCRIPTION_PREMIUM_ANNUAL
  }
  return events.SELECT_BASIC_SUBSCRIPTION
}

export default {
  FREE_YEAR_DAYS: 365,
  PLANS,
  PROMO_CODES: {
    _2020: environment.isProduction ? 'TatOyXiQ' : 'BlbolDk9',
    EARLY: 'EARLY50',
  },
  TRIAL_DAYS,

  calculateAmountInCentsWithCoupon,
  findPromoCode,
  getPlanById,
  getPlanFeatures,
  getPlanFreeTrialDays,
  getPlanLevel2,
  getPlanName,
  getSubscriptionEventName,
  isAnnualPlan,
  isBiAnnualPlan,
  isFreePlan,
  isMonthlyPlan,
  isPlanFreeTrialEligible,
}
export type { Plan }
