import { navigate } from 'gatsby'
import _ from 'lodash'
import { toast } from 'react-toastify'

type NotificationType = 'error' | 'success' | undefined

function notify(value: unknown, autoCloseDuration?: number) {
  if (!value) return

  const message = _.upperFirst(_.get(value, 'message') || value)
  const type = _.get(value, 'type') || 'info'

  toast[type](message, { autoClose: autoCloseDuration || undefined })
}

function notifyAndRedirect({
  back,
  delay,
  message,
  to,
  type = 'success',
}: {
  back?: boolean
  delay?: boolean
  message: unknown
  to?: string
  type: NotificationType
}) {
  notify({
    message,
    type,
  })

  setTimeout(
    () => {
      if (back) {
        navigate(-1)
      } else {
        if (!_.isNil(to)) {
          navigate(to)
        } else {
          window.location.reload()
        }
      }
    },
    delay ? 3500 : 0
  )
}

function notifyError(message = 'Sorry, something went wrong') {
  notify({
    message,
    type: 'error',
  })
}

function notifySuccess(message: string) {
  notify(
    {
      message,
      type: 'success',
    },
    3000
  )
}

function notifyInfo(message: string) {
  notify({
    message,
  })
}

export default {
  notifyAndRedirect,
  notifyError,
  notifySuccess,
  notifyInfo,
}
