import _ from 'lodash'
import qs from 'qs'
import paths from 'constants/paths'
import storage from 'constants/storage'
import * as cookie from 'libs/cookie'

type QueryStringObject = {
  [key: string]: any
}

function getQueryString(url: Location = window.location): QueryStringObject {
  return qs.parse(url.search, { ignoreQueryPrefix: true })
}

function getUrlWithoutQueryString(url: Location | string = window.location): string {
  return _.isString(url) ? url.split('?')[0] : url.href.split('?')[0]
}

function format(url: Location | string = window.location): string {
  const urlConstructed = _.isString(url) ? new URL(url) : url
  return `${urlConstructed.host}${urlConstructed.pathname}${urlConstructed.search ?? ''}`
}

function setUrl({
  url = window.location,
  queryStringObject = {},
}: {
  url?: Location | string
  queryStringObject?: QueryStringObject
}): void {
  window.history.replaceState(
    {},
    '',
    `${getUrlWithoutQueryString(url)}${_.isEmpty(queryStringObject) ? '' : '?'}${qs.stringify(
      queryStringObject
    )}`
  )
}

function setUrlWithoutQueryStringKeyValue(key: string): string {
  const currentQueryString = getQueryString()

  delete currentQueryString[key]

  const urlWithoutQuery = `${getUrlWithoutQueryString(window.location)}?${qs.stringify(
    currentQueryString
  )}`
  window.history.replaceState({}, '', urlWithoutQuery)
  return urlWithoutQuery
}

function generateUrlWithoutQueryStringKeyValue(key: string): string {
  const currentQueryString = getQueryString()
  delete currentQueryString[key]
  return `${getUrlWithoutQueryString(window.location)}?${qs.stringify(currentQueryString)}`
}

function turnObjectIntoQueryString(object: string): string {
  const test = Object.keys(object)
    .map((key) => `${key}=${object[key]}`)
    .join('&')
  return test
}

function createSignInQueryString(userId: string, otherParameters?: any): string {
  return turnObjectIntoQueryString({
    userId,
    accessToken: cookie.getCookie(storage.ACCESS_TOKEN_KEY),
    ...otherParameters,
  })
}

function grabMagicLinkURL(): string {
  const bannedMagicLinkPaths = [paths.SIGN_IN]
  let currentPath = window.location.pathname
  if (!currentPath.endsWith('/')) currentPath = `${currentPath}/`
  if (bannedMagicLinkPaths.includes(currentPath)) {
    return `${window.location.origin}/app/`
  }
  return window.location.href
}

export default {
  createSignInQueryString,
  format,
  generateUrlWithoutQueryStringKeyValue,
  getQueryString,
  getUrlWithoutQueryString,
  grabMagicLinkURL,
  setUrl,
  setUrlWithoutQueryStringKeyValue,
  turnObjectIntoQueryString,
}
