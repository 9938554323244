const APP_ENV = process.env.GATSBY_BOLD_APP_ENV || ''

export const isDevelopment = ['development', 'local'].includes(APP_ENV)
export const isStaging = ['staging'].includes(APP_ENV)
export const isProduction = ['production'].includes(APP_ENV)
export const isTesting = ['development', 'test'].includes(APP_ENV, process.env.NODE_ENV)

export const DOMAIN_PRODUCTION = 'https://agebold.com'
export const DOMAIN = isProduction
  ? DOMAIN_PRODUCTION
  : isStaging
    ? 'https://staging.agebold.com'
    : 'http://localhost:3001'

export default {
  APP_ENV,
  DOMAIN,
  DOMAIN_PRODUCTION,

  isDevelopment,
  isProduction,
  isStaging,
  isTesting,
}
