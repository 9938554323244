import phoneNumbers from '@bold/common/constants/phone-numbers'
import _ from 'lodash'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import storage from 'constants/storage'
import formatPhone from 'libs/format-phone'
import * as userApiRequest from 'libs/user-api-request'
import validations from 'libs/validations'

const DEMO_CLINICAL_KEY = 'demo'
const CQC_KEY = 'cqc'
const CUTTLEFISH_KEY = 'cuttlefish'
const CUTTLEFISH_ALIGN_KEY = 'cuttlefish-align-senior-care'
const CUTTLEFISH_LIFEWORKS_KEY = 'cuttlefish-lifeworks-advantage'
const CUTTLEFISH_PERENNIAL_KEY = 'cuttlefish-perennial-advantage'
const CUTTLEFISH_PRUITTHEALTH_KEY = 'cuttlefish-pruitthealth-premier'
const GATOR_KEY = 'gator'
const GROUPER_KEY = 'grouper'
const HERRING_KEY = 'herring'
const HUMPBACK_KEY = 'humpback'
const OCTOPUS_KEY = 'octopus'
const ORCA_KEY = 'orca'
const OTTER_KEY = 'otter'
const PATINA_KEY = 'patina'
const REEF_KEY = 'reef'
const SEAL_KEY = 'seal'
const TRIAL_KEY = 'trial'
const UCARE_KEY = 'ucare'

const PARTNERS: any = {
  [DEMO_CLINICAL_KEY]: {
    internalId: DEMO_CLINICAL_KEY,
    campaign: 'clinical-demo',
    name: 'Partner',
    slug: 'enterprise',
  },
  [CUTTLEFISH_ALIGN_KEY]: {
    contractIds: ['H3274', 'H9917', 'H1277', 'H6832'],
    internalId: CUTTLEFISH_KEY,
    campaign: 'align-senior-care',
    name: 'Align Senior Care',
    slug: 'alignseniorcare',
  },
  [CUTTLEFISH_LIFEWORKS_KEY]: {
    contractIds: ['H2185'],
    internalId: CUTTLEFISH_KEY,
    campaign: 'lifeworks-advantage',
    name: 'LifeWorks Advantage',
    slug: 'lifeworksadvantage',
  },
  [CUTTLEFISH_PERENNIAL_KEY]: {
    contractIds: ['H3419', 'H8797'],
    internalId: CUTTLEFISH_KEY,
    campaign: 'perennial-advantage',
    name: 'Perennial Advantage',
    slug: 'perennialadvantage',
  },
  [CUTTLEFISH_PRUITTHEALTH_KEY]: {
    contractIds: ['H3291'],
    internalId: CUTTLEFISH_KEY,
    campaign: 'pruitthealth-premier',
    name: 'PruittHealth Premier',
    slug: 'pruitthealth',
  },
  [CQC_KEY]: {
    internalId: CQC_KEY,
    campaign: 'cinqcare',
    name: 'CINQCARE',
    slug: 'cinqcare',
  },
  [GATOR_KEY]: {
    internalId: GATOR_KEY,
    campaign: 'genspace',
    name: 'Wallis Annenberg GenSpace',
    slug: 'genspace',
  },
  [GROUPER_KEY]: {
    internalId: GROUPER_KEY,
    campaign: 'uhc-group',
    name: 'UnitedHealthcare',
    phoneNumber: '1 (855) 608-1393',
    phoneNumberDisplay: '1 (855) 608-1393 (TTY: 711)',
    slug: 'uhcgroup',
  },
  [HERRING_KEY]: {
    internalId: HERRING_KEY,
    campaign: 'human-good',
    name: 'Human Good',
    nameTechnical: 'Human Good®',
    slug: 'humangoodlg',
  },
  [HUMPBACK_KEY]: {
    internalId: HUMPBACK_KEY,
    campaign: 'humana',
    name: 'Humana',
    phoneNumber: '1 (855) 650-1326',
    phoneNumberDisplay: '1 (855) 650-1326 (TTY: 711)',
    slug: 'humana',
  },
  [OCTOPUS_KEY]: {
    internalId: OCTOPUS_KEY,
    campaign: 'ochsner',
    name: 'Ochsner',
    slug: 'ochsner',
  },
  [ORCA_KEY]: {
    internalId: ORCA_KEY,
    campaign: 'one-pass',
    name: 'One Pass',
    nameTechnical: 'One Pass™',
    slug: 'onepass',
    terms: 'https://www.rallyhealth.com/corporate/terms/en-US/',
  },
  [OTTER_KEY]: {
    internalId: OTTER_KEY,
    campaign: 'otter',
    name: 'Optum',
    phoneNumber: '1 (855) 528-0851',
    phoneNumberDisplay: '1 (855) 528-0851 (TTY: 711)',
    slug: 'optumaco',
  },
  [PATINA_KEY]: {
    internalId: ORCA_KEY,
    name: 'Patina',
    slug: 'patina',
  },
  [REEF_KEY]: {
    internalId: REEF_KEY,
    campaign: 'renewactive',
    name: 'Renew Active',
    nameTechnical: 'Renew Active® by UnitedHealthcare®',
    slug: 'renewactive',
    terms: 'https://uhcrenewactive.com/terms',
  },
  [SEAL_KEY]: {
    internalId: ORCA_KEY,
    campaign: 'scan',
    name: 'SCAN Health Plan through One Pass™',
    slug: 'scan',
  },
  [UCARE_KEY]: {
    internalId: UCARE_KEY,
    campaign: 'ucare',
    name: 'UCare',
    slug: 'ucare',
  },
}

// prettier-ignore
const PARTNERS_CLINICAL = [
  DEMO_CLINICAL_KEY,
  GROUPER_KEY,
  // HUMPBACK_KEY,
  OCTOPUS_KEY,
  OTTER_KEY,
]

const PARTNERS_FITNESS = [
  CUTTLEFISH_ALIGN_KEY,
  CUTTLEFISH_LIFEWORKS_KEY,
  CUTTLEFISH_PERENNIAL_KEY,
  CUTTLEFISH_PRUITTHEALTH_KEY,
  GATOR_KEY,
  HERRING_KEY,
  ORCA_KEY,
  PATINA_KEY,
  REEF_KEY,
  SEAL_KEY,
  TRIAL_KEY,
]

const PARTNERS_ALWAYS_ELIGIBLE = [
  DEMO_CLINICAL_KEY,
  GATOR_KEY,
  HERRING_KEY,
  storage.MASS_ADVANTAGE,
  'trial',
]

const PARTNERS_WITH_ELIGIBILITY_FILE = [
  CUTTLEFISH_ALIGN_KEY,
  CUTTLEFISH_LIFEWORKS_KEY,
  CUTTLEFISH_PERENNIAL_KEY,
  CUTTLEFISH_PRUITTHEALTH_KEY,
  GROUPER_KEY,
  // HUMPBACK_KEY,
  OTTER_KEY,
]

const REEF_ORCA_SOURCES = ['special', ORCA_KEY, PATINA_KEY, REEF_KEY, SEAL_KEY]

const PARTNERS_ORCA = [ORCA_KEY, SEAL_KEY]

const SRC_IDS_NO_ELIG_CHECK = [storage.MASS_ADVANTAGE] // todo +octopus?

/* Cuttlefish only */
const mapMcoContractToPartner = (mcoContractNumber = null) => {
  for (const [key, value] of Object.entries(PARTNERS).filter(([partner]) =>
    _.startsWith(partner, CUTTLEFISH_KEY)
  )) {
    if (value.contractIds?.includes(mcoContractNumber)) {
      return PARTNERS[key]
    }
  }
  return null
}

// Used if matched cuttlefish user from regular LP ie srcId would
// be null, & need to lookup which of the 4 cuttlefish logos to use
function getLogoForCuttlefishUser(user) {
  if (user?.srcId) {
    return `/enterprise/${user?.srcId}.svg`
  }
  const partner = mapMcoContractToPartner(user?.enterpriseMember?.mcoContractNumber)
  return `/enterprise/cuttlefish-${partner?.campaign}.svg`
}

// prettier-ignore
const getCampaign = (key: string) => PARTNERS[key].campaign
const getHomePath = (key: string) =>
  key === 'special'
    ? paths.HOME_SPECIAL
    : PARTNERS[key]?.slug
      ? `/${PARTNERS[key].slug}/`
      : paths.HOME
const getInternalId = (key: string) => PARTNERS[key]?.internalId
const getLogo = (key: string, user) =>
  key === CUTTLEFISH_KEY ? getLogoForCuttlefishUser(user) : `/enterprise/${getInternalId(key)}.svg`
const getName = (key, srcId = null) =>
  key === CUTTLEFISH_KEY && srcId ? PARTNERS[srcId]?.name : (PARTNERS[key]?.name ?? '')
const getTerms = (key) => PARTNERS[key]?.terms

async function convertReefOrcaUserToEnterprise({
  data,
  onError,
  onSuccess,
}: {
  data: {
    enterpriseId: string
    externalId: string
    // validateCode?: boolean
  }
  onError?: () => Promise<void>
  onSuccess?: () => Promise<void>
}) {
  if (!data.enterpriseId) return

  const response = await userApiRequest
    .associateUserWithEnterprise({
      ...data,
      validateCode: true, // so nobody can have just a random URL or type in any code
    })
    .catch((error) => {
      console.error(error)
      if (onError) onError(response)
    })

  if (response.statusCode !== statusCodes.POST_SUCCESS) {
    if (onError) await onError(response)
    return
  }

  if (response.statusCode === statusCodes.POST_SUCCESS && onSuccess) await onSuccess()

  return response?.data
}

function getNameTechnical(key: string, user = null) {
  if (key === CUTTLEFISH_KEY) {
    if (user?.srcId) {
      return PARTNERS[user?.srcId].name
    }
    const partner = mapMcoContractToPartner(user?.enterpriseMember?.mcoContractNumber)
    return partner?.name
  }
  if (!PARTNERS[key]) return ''
  return PARTNERS[key].nameTechnical || PARTNERS[key].name
}

function getReefAndOrOrcaName(key: string, conjunction: string = ' or ') {
  const orcaName = getNameTechnical(ORCA_KEY)
  const reefName = getNameTechnical(REEF_KEY)
  if (PARTNERS_ORCA.includes(key)) {
    return `${orcaName} member code`
  }
  if (key === REEF_KEY) {
    return `${reefName} confirmation code`
  }
  return `${reefName + conjunction + orcaName} code`
}

function getPhoneNumber(key, format = false) {
  if (PARTNERS[key]?.phoneNumber) {
    return format ? formatPhone.display(PARTNERS[key]?.phoneNumber) : PARTNERS[key]?.phoneNumber
  }

  return format ? formatPhone.display(phoneNumbers.DEFAULT) : phoneNumbers.DEFAULT
}

function getPhoneNumberDisplay(key) {
  return PARTNERS[key]?.phoneNumberDisplay ?? getPhoneNumber(key, true)
}

function determineReefOrOrca(key: string) {
  switch (key) {
    case null:
    case undefined:
    case 'special':
    case 'patina': {
      return ['reef', 'orca']
    }
    case 'reef': {
      return ['reef']
    }
    case 'orca':
    case 'seal': {
      return ['orca']
    }
    default: {
      return []
    }
  }
}

function skipEligibilityCheck(srcId: string) {
  return SRC_IDS_NO_ELIG_CHECK.includes(srcId)
}

function isClinical(key) {
  return PARTNERS_CLINICAL.includes(key)
}

function isFitness(key) {
  return PARTNERS_FITNESS.includes(key)
}

function isOrcaPartner(key) {
  return PARTNERS_ORCA.includes(key)
}

async function validateExternalId(externalId: string) {
  try {
    await validations.REEF_ORCA_EXTERNAL_ID.validate(externalId)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export default {
  CQC_KEY,
  CUTTLEFISH_ALIGN_KEY,
  CUTTLEFISH_KEY,
  CUTTLEFISH_LIFEWORKS_KEY,
  CUTTLEFISH_PERENNIAL_KEY,
  CUTTLEFISH_PRUITTHEALTH_KEY,
  DEMO_CLINICAL_KEY,
  GATOR_KEY,
  GROUPER_KEY,
  HERRING_KEY,
  HUMPBACK_KEY,
  OCTOPUS_KEY,
  ORCA_KEY,
  OTTER_KEY,
  PARTNERS,
  PARTNERS_ALWAYS_ELIGIBLE,
  PARTNERS_WITH_ELIGIBILITY_FILE,
  PATINA_KEY,
  REEF_KEY,
  REEF_ORCA_SOURCES,
  SEAL_KEY,
  TRIAL_KEY,
  UCARE_KEY,

  convertReefOrcaUserToEnterprise,
  determineReefOrOrca,
  getCampaign,
  getHomePath,
  getInternalId,
  getLogo,
  getName,
  getNameTechnical,
  getPhoneNumber,
  getPhoneNumberDisplay,
  getReefAndOrOrcaName,
  getTerms,
  isClinical,
  isFitness,
  isOrcaPartner,
  skipEligibilityCheck,
  validateExternalId,
}
